<template>
    <div>
        <div v-if="viewno == 1">
            <div class="col-md-12 flex-between-row">
                <div class="col-md-6">
                    <h3 class="page-header"><i class="fa fa fa-user-md animated bounceInDown show-info"></i> View Pending
                        Leads</h3>
                </div>
                <div class="col-md-6 text-r">
                    <button class="btn btn-primary" @click="refresh()"><i class="fa fa-refresh"></i> Referesh</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="info-box  bg-success-dark  text-white">
                        <div class="info-icon" style="background-color:#7e7e7e">
                            <i class="fa fa-calendar fa-2x"></i>
                        </div>
                        <div class="info-details">
                            <h4>Total Leads: <span class="badge bg-danger" style="font-size:medium;">{{ totalleads }}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="info-box  bg-danger-dark  text-white">
                        <div class="info-icon" style="background-color:#7e7e7e">
                            <i class="fa fa-calendar fa-2x"></i>
                        </div>
                        <div class="info-details">
                            <h4>Total Sale : <span class="badge bg-success" style="font-size:medium;">{{ totalsale }}</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-cascade">

                        <div class="panel-body ">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-horizontal cascde-forms">

                                        <div class="form-group flex-row CHK">
                                            <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                            <div class="col-lg-8 col-md-9">
                                                <input class="form-control form-cascade-control input-small"
                                                    v-model="customername" type="text" value="" />
                                            </div>
                                        </div>
                                        <!-- <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Address</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="customername" type="text" value="" />
                                        </div>
                                    </div> -->

                                        <div class="form-group flex-row">
                                            <label class="col-lg-4 col-md-3 control-label">Entry Date : FROM</label>
                                            <div class="col-lg-8 col-md-9">
                                                <input class="form-control form-cascade-control input-small"
                                                    v-model="entrydatefrom" type="date" />
                                            </div>
                                        </div>
                                        <div class="form-group flex-row">
                                            <label class="col-lg-4 col-md-3 control-label">Ratings</label>
                                            <div class="col-lg-8 col-md-9">
                                                <select name="" id="" class="form-control form-cascade-control input-small"
                                                    v-model="ratings">
                                                    <option :value="0">--Select--</option>
                                                    <option :value="1">--1--</option>
                                                    <option :value="2">--2--</option>
                                                    <option :value="3">--3--</option>
                                                    <option :value="4">--4--</option>
                                                    <option :value="5">--5--</option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Dispose Date : FROM</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="disposedatefrom" type="text" />
                                        </div>
                                    </div> -->
                                        <!-- <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Schedule Date : FROM</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="scheduledatefrom" type="text" />
                                        </div>
                                    </div> -->
                                        <div class="form-group flex-row" style="display:none">
                                            <label class="col-lg-4 col-md-3 control-label">Status</label>
                                            <div class="col-lg-8 col-md-9">
                                                <select class="form-control form-cascade-control input-small"
                                                    v-model="isdispose">
                                                    <option value="0">SELECT</option>
                                                    <option value="0">PENDING</option>
                                                    <option value="1">DISPOSE</option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group flex-row" v-if="loggedinuser?.role<2">
                                        <label class="col-lg-4 col-md-3 control-label">Followed By 12</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select v-model="followbyid" class="form-control form-cascade-control input-small">
                                                <option v-for="filter in employees.filter(property => property.id ==loggedinuser.id )" :key="filter.id" :value="filter.id" ><span >{{filter.name}}</span></option>
                                            </select>             
                                        </div>
                                    </div> -->
                                        <!-- <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Complaint No.</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="complaintno" type="number" />
                                        </div>
                                    </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6 CHK">
                                    <div class="form-horizontal cascde-forms">
                                        <div class="form-group flex-row CHK">
                                            <label class="col-lg-4 col-md-3 control-label">Customer Mobile</label>
                                            <div class="col-lg-8 col-md-9">
                                                <input class="form-control form-cascade-control input-small"
                                                    v-model="customermobile" type="number" />
                                            </div>
                                        </div>

                                        <div class="form-group flex-row">
                                            <label class="col-lg-4 col-md-3 control-label">Entry Date : TO</label>
                                            <div class="col-lg-8 col-md-9">
                                                <input class="form-control form-cascade-control input-small"
                                                    v-model="entrydateto" type="date" />
                                            </div>
                                        </div>
                                        <!-- <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Dispose Date : TO</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="disposedateto" type="date" />
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Schedule Date : TO</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="scheduledateto" type="text" value="" />
                                        </div>
                                    </div>
                                    -->
                                        <div class="form-group flex-row">
                                            <label class="col-lg-4 col-md-3 control-label">Entry By</label>
                                            <div class="col-lg-8 col-md-9">
                                                <select v-model="entrybyid"
                                                    class="form-control form-cascade-control input-small">
                                                    <option v-for="filter in employees" :key="filter.id" :value="filter.id">
                                                        {{ filter.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group flex-row">
                                    <div class="col-md-9" style="">
                                        <input type="submit" @click="search()" value="Search"
                                            class="btn bg-primary text-white btn-md">
                                    </div>
                                    <div class="col-md-3" style="">
                                        <input type="submit" @click="initial()" value="Clear"
                                            class="btn bg-danger text-white btn-md">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 card-body ft-14 mt-20 card-type">
                    <div class="col-md-12 flex-between-row">
                        <h5 style="color:green">Conversation Search</h5>
                        <div>
                            <label for="">Searched Conv : </label>
                            <span class="badge bg-primary">{{ searchconvercount }}</span>
                        </div>
                    </div>

                    <div class="row mt-30">
                        <!-- <div class="col-md-4 row">
                            <div class="col-md-5">
                                <strong>Followed By</strong>
                            </div>
                            <div class="col-md-7">
                                <select class="form-control col-mb-1" v-model="followedid">
                                    <option :value=0>-----Select------</option>
                                    <option v-for="item in employees" :value="item.id" :key="item.id">{{ item.name }} </option>
                                </select>
                            </div>
                        </div> -->
                        <div class="col-md-6 row">
                            <div class="col-md-6">
                                <strong>Conversation Date From</strong>
                            </div>
                            <div class="col-md-6">
                                <input type="date" class="form-control form-cascade-control input-small"
                                    v-model="entrydatefrom">
                            </div>
                        </div>
                        <div class="col-md-6 row">
                            <div class="col-md-6">
                                <strong>Conversation Date To</strong>
                            </div>
                            <div class="col-md-6">
                                <input type="date" class="form-control form-cascade-control input-small"
                                    v-model="entrydateto">
                            </div>
                        </div>
                        <div class="col-md-12 text-right">
                            <button type="button" class="btn bg-success text-white username btnsearch"
                                @click="conversationfetch()" style="margin-top: 31px;">Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <div class="panel">
                    <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                        <table class="table users-table table-condensed table-hover table-bordered table-sm">
                            <thead class="table-font" style="font-size:13px;">
                                <tr>
                                    <th>#</th>
                                    <!-- <th>Vsdigi ID</th> -->
                                    <th class="visible-lg">NAME</th>
                                    <th>MOBILE</th>
                                    <th>Alternate Mobile</th>
                                    <th>ADDRESS</th>
                                    <th>Cust Ref.</th>
                                    <!-- <th>Complaint No.</th> -->
                                    <th>Remark</th>
                                    <!-- <th>PRODUCT</th> -->
                                    <!-- <th>SCHEDULED DATE</th> -->
                                    <th>ENTRY BY<br>ENTRY DATE</th>
                                    <th>Advisor</th>
                                    <th>RATINGS</th>
                                    <th v-if="loginusercheck(193)">ACTION </th>
                                    <th>Fake /<br>Relevant</th>
                                    <th>Edit </th>
                                    <th>Nature Lead</th>
                                </tr>
                            </thead>
                            <pending-row v-for="(item, index) in leads" :key="item.id" :item="item"
                                :index="index"></pending-row>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <new-lead v-if="viewno == 2"></new-lead>
        <modal name="leadfakemodal" :width="400" :height="250" draggable=".window-header">
            <div class="col-md-12 pb-10 text-center">
                <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">
                    Lead Fake/Relevant Update
                </h3>
            </div>
            <div class="col-md-12">
                <label for="remark">Conversation</label>
            </div>
            <div class="col-md-12">
                <textarea id="remark" class="form-control " v-model="detail"></textarea>
            </div>

            <div class="col-md-12 pt-20 pb-20" style="display:flex;flex-direction:row">
                <button class="btn bg-primary text-white btn-sm chkcondition" style="margin-right:10px;"
                    @click="updatefakedata()">
                    <span v-if="edititem?.isfake ==1">Relevant</span>
                    <span v-else>Fake Lead</span>
                </button>
                <button class="btn bg-danger text-white btn-sm chkcondition" @click="closefakemodal()">Close</button>
            </div>
        </modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PendingRow from './PendingRow.vue'
import Constants from '../../components/utilities/Constants.vue';
import NewLead from './NewLead.vue';
export default {
    components: { PendingRow, NewLead },
    mixins: [Constants],
    data() {
        return {
            // leads:[],
            customername: '',
            customermobile: '',
            followbyid: 0,
            entrydatefrom: '',
            disposedatefrom: '',
            requesttype: 5,
            scheduledatefrom: '',
            entrybyid: 0,
            disposedateto: '',
            entrydateto: '',
            scheduledateto: '',
            leadtype: 0,
            isdispose: 0,
            searchconvercount: 0,
            complaintno: '',
            ratings: 0,
            totalsale: 0,
            totalleads: 0,
            detail:'',
            rows:0
        }
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters(['employees', 'leads', 'loggedinuser', 'viewno','edititem'])
    },
    methods: {
        refresh() {
            this.initial()
            this.$store.dispatch("fetchemployees")
            this.$store.dispatch("fetchattributes")
            let param = { isdispose: 0, requesttype: 5, isschedule: 0 }
            this.$store.commit('assignloadingstatus', 1)
            this.$http.post('api/leads/fetchrecords', param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    this.errorShow(err)
                });
            this.$http.post('api/leadssales/totalcount')
                .then((response) => this.processLeadSaleTotalResponse(response.data))
                .catch((err) => {
                    this.errorShow(err)
                });
            this.$http.post('api/leads/total/count', param)
                .then((response) => this.processLeadTotalResponse(response.data))
                .catch((err) => {
                    this.errorShow(err)
                });
        },
        processLeadSaleTotalResponse(data) {
            this.totalsale = data
        },
        processLeadTotalResponse(data) {
            this.totalleads = data
        },
        search() {
            let param = {
                isdispose: 0,
                customername: this.customername,
                customermobile: this.customermobile,
                followbyid: this.followbyid,
                entrydatefrom: this.entrydatefrom,
                disposedatefrom: this.disposedatefrom,
                requesttype: 5,
                scheduledatefrom: this.scheduledatefrom,
                entrybyid: this.entrybyid,
                disposedateto: this.disposedateto,
                entrydateto: this.entrydateto,
                scheduledateto: this.scheduledateto,
                complaintno: this.complaintno,
                leadtype: this.leadtype,
                ratings: this.ratings,
                isschedule: 0
            }
            this.$store.commit('assignloadingstatus', 1)
            this.$http.post('api/leads/fetchrecords', param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    this.errorShow(err)
                });
        },
        processLeadResponse(data) {
            this.$store.commit('assignloadingstatus', 0)
            this.$store.commit("assignleads", data.lead)
            this.rows=data.count
        },
        errorShow() {
            this.$store.commit('assignloadingstatus', 0)
            alert("Something went wrong, please try after sometime.")
        },
        initial() {
            this.customername = ''
            this.customermobile = ''
            this.followbyid = 0,
                this.entrydatefrom = ''
            this.disposedatefrom = ''
            this.scheduledatefrom = ''
            this.entrybyid = 0
            this.disposedateto = ''
            this.entrydateto = ''
            this.scheduledateto = ''
            this.leadtype = 0
            this.isdispose = 0
            this.complaintno = ''
        },
        conversationfetch() {
            let param = { requesttype: 5, followedid: this.followedid, entrydatefrom: this.entrydatefrom, entrydateto: this.entrydateto }
            this.$http.post("api/conversation/fetch/byrequest", param)
                .then((response) => this.ProcessConversation(response.data))
                .catch((err) => {
                    this.errorShow(err)
                });
        },
        ProcessConversation(data) {
            this.searchconvercount = data.count
        },
        closefakemodal(){
            this.$modal.hide('leadfakemodal')
        },
        updatefakedata(){
            let param={id:this.edititem?.id,column:'isfake',value:this.edititem?.isfake==0?1:0}
            this.$http.post('api/leads/updatefield',param)
            .then((response) => this.processFakeDetail(response.data))
            .catch((err) => {
                console.log(err)
            });
           
        },
        processFakeDetail(){
            this.$modal.hide('leadfakemodal')
            let param = {
                leadid:this.edititem?.id,detail:this.detail,
                requesttype:5,isfake:this.edititem?.isfake==0?1:0
            }
            this.$http.post('api/leads/customerconversation',param)
            .then((response) => this.processCOnverDetail(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processCOnverDetail(){
            this.refresh()
            this.detail=''
            this.$store.commit('assignedititem',[])
        }
        
    },
}
</script>
<style>.flex-row {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
}</style>